/**
 * 路由配置信息
 *
 * @author terrfly
 * @site https://www.jeepay.vip
 * @date 2021/5/8 07:18
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// hack router push callback
// [解决 vue-router跳转相同路径报错 ]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
    {path: '/hub/:beingFiToken', name: 'Hub', component: () => import('../views/Hub.vue')}, //自动分发器
    {path: '/error', name: 'Error', component: () => import('../views/Error.vue')},
    {path: '/oauth2Callback/:beingFiToken', name: 'Oauth2Callback', component: () => import('../views/Oauth2Callback.vue')}, //oauth回调地址
    { path: '/cashier', name: 'Cashier', component: () => import('../views/Cashier') },
    { path: '/cashier/wxpay', name: 'CashierWxpay', component: () => import('../views/payway/Wxpay.vue') },
    { path: '/cashier/alipay', name: 'CashierAlipay', component: () => import('../views/payway/Alipay.vue') },
    { path: '/cashier/ysfpay', name: 'CashierYsfpay', component: () => import('../views/payway/Ysfpay.vue') },
    { path: '/cashier/IdrBca', name: 'IDR_BCA', component: () => import('../views/payway/Idr/IDR_BCA.vue') },
    { path: '/cashier/IdrEwallet', name: 'IDR_EWALLET', component: () => import('../views/payway/Idr/IDR_EWALLET.vue') },
    { path: '/cashier/IdrVa', name: 'IDR_VA', component: () => import('../views/payway/Idr/IDR_VA.vue') },
    { path: '/cashier/IdrBni', name: 'IDR_BNI', component: () => import('../views/payway/Idr/IDR_BNI.vue') },
    { path: '/cashier/IdrBri', name: 'IDR_BRI', component: () => import('../views/payway/Idr/IDR_BRI.vue') },
    { path: '/cashier/IdrCimb', name: 'IDR_CIMB', component: () => import('../views/payway/Idr/IDR_CIMB.vue') },
    { path: '/cashier/IdrDana', name: 'IDR_DANA', component: () => import('../views/payway/Idr/IDR_DANA.vue') },
    { path: '/cashier/IdrDanamon', name: 'IDR_DANAMON', component: () => import('../views/payway/Idr/IDR_DANAMON.vue') },
    { path: '/cashier/IdrLinkaja', name: 'IDR_LINKAJA', component: () => import('../views/payway/Idr/IDR_LINKAJA.vue') },
    { path: '/cashier/IdrMandiri', name: 'IDR_MANDIRI', component: () => import('../views/payway/Idr/IDR_MANDIRI.vue') },
    { path: '/cashier/IdrMaybank', name: 'IDR_MAYBANK', component: () => import('../views/payway/Idr/IDR_MAYBANK.vue') },
    { path: '/cashier/IdrOvo', name: 'IDR_OVO', component: () => import('../views/payway/Idr/IDR_OVO.vue') },
    { path: '/cashier/IdrPermata', name: 'IDR_PERMATA', component: () => import('../views/payway/Idr/IDR_PERMATA.vue') },
    { path: '/cashier/IdrQris', name: 'IDR_QRIS', component: () => import('../views/payway/Idr/IDR_QRIS.vue') },
    { path: '/cashier/IdrShopeePay', name: 'IDR_SHOPEEPAY', component: () => import('../views/payway/Idr/IDR_SHOPEEPAY.vue') },
    { path: '/cashier/MmkKbz', name: 'MMK_KBZ', component: () => import('../views/payway/Mmk/MMK_KBZ.vue') },
    { path: '/cashier/MmkWavemoney', name: 'MMK_WAVEMONEY', component: () => import('../views/payway/Mmk/MMK_WAVEMONEY.vue') },
    { path: '/cashier/MmkKbzQr', name: 'MMK_KBZ_QR', component: () => import('../views/payway/Mmk/MMK_KBZ_QR.vue') },
    { path: '/cashier/MmkAyaQr', name: 'MMK_AYA_QR', component: () => import('../views/payway/Mmk/MMK_AYA_QR.vue') },
]

const router = new VueRouter({
  mode: 'hash', //history 需要nginx适配    hash：是#的格式。
  base: "",
  routes
})

export default router
